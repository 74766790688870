// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fbbTOeAPcRlq2JzApm9f{display:flex;flex-direction:row;justify-content:center;gap:42px}@media(max-width: 576.02px){.fbbTOeAPcRlq2JzApm9f{gap:20px;flex-direction:column}}.fbbTOeAPcRlq2JzApm9f .CODxAFxjfQrR4Hq9itJQ{text-align:left}.fbbTOeAPcRlq2JzApm9f .CODxAFxjfQrR4Hq9itJQ:before{background-size:contain;width:28px;height:28px;margin-bottom:-8px;margin-left:-36px;margin-right:12px}.fbbTOeAPcRlq2JzApm9f .CODxAFxjfQrR4Hq9itJQ.fpulaYtNa9sOMyokUlRl:before{display:inline-block;background-repeat:no-repeat;content:"";background-image:url("../assets/icons/Mail.svg")}.fbbTOeAPcRlq2JzApm9f .CODxAFxjfQrR4Hq9itJQ.ZzLSKYNCoHEiNJDEEC7x{width:200px}.fbbTOeAPcRlq2JzApm9f .CODxAFxjfQrR4Hq9itJQ.ZzLSKYNCoHEiNJDEEC7x:before{display:inline-block;background-repeat:no-repeat;content:"";background-image:url("../assets/icons/Link.svg")}.fbbTOeAPcRlq2JzApm9f .CODxAFxjfQrR4Hq9itJQ:hover:before{filter:brightness(0) invert(1)}.fbbTOeAPcRlq2JzApm9f .CODxAFxjfQrR4Hq9itJQ.f5Ei5hrr9RLFbUbwwV7Q{width:200px}.fbbTOeAPcRlq2JzApm9f .CODxAFxjfQrR4Hq9itJQ.f5Ei5hrr9RLFbUbwwV7Q:before{display:inline-block;background-repeat:no-repeat;content:"";background-image:url("../assets/icons/Check.svg")}.c8pnAuKvXO_i_DcSzS_O{display:flex;flex-direction:column;gap:40px}.c8pnAuKvXO_i_DcSzS_O .PhoneInputCountry{align-self:unset;margin-bottom:6px}.c8pnAuKvXO_i_DcSzS_O input{margin-top:0}.c8pnAuKvXO_i_DcSzS_O .lqGUK17dgB09e0C4Om8i{color:#c0453a;font-size:1.3rem;line-height:1.8rem}`, ""]);
// Exports
export var buttonsWrapper = `fbbTOeAPcRlq2JzApm9f`;
export var iconButton = `CODxAFxjfQrR4Hq9itJQ`;
export var mailButton = `fpulaYtNa9sOMyokUlRl`;
export var linkButton = `ZzLSKYNCoHEiNJDEEC7x`;
export var copiedButton = `f5Ei5hrr9RLFbUbwwV7Q`;
export var contactSellerMailPopUp = `c8pnAuKvXO_i_DcSzS_O`;
export var textAreaError = `lqGUK17dgB09e0C4Om8i`;
export default ___CSS_LOADER_EXPORT___;
